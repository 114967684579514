import store from "./index";
import {randomBytes} from 'crypto'

function newId() {
    return randomBytes(4).toString('hex')
}

export default {
    namespaced: true,
    state: {
        timeout: 5000,
        alerts: []
    },
    mutations: {
        addAlert(state, payload) {
            let id = newId()
            let newPayload = JSON.parse(JSON.stringify(Object.assign({}, payload))) // cus payload = observer
            newPayload.id = id
            state.alerts = [...state.alerts, newPayload]
            setTimeout(function () {
                let alertArray = JSON.parse(JSON.stringify(state.alerts)) // cus state observers are dicks
                let newPayloadIndex = alertArray.findIndex(x => x.id === id)
                state.alerts.splice(newPayloadIndex, 1)
            }, state.timeout)
        },
        removeToast(state, id) {
            let alertArray = JSON.parse(JSON.stringify(state.alerts)) // cus state observers are dicks
            let newPayloadIndex = alertArray.findIndex(x => x.id === id)
            state.alerts.splice(newPayloadIndex, 1)
        },
    },
    actions: {
        addToast({commit}, payload) {
            commit('addAlert', payload)
        },
        removeToast({commit}, id) {
            commit('removeToast', id)
        },
        toastSuccess({commit}, text) {
            commit('addAlert', {
                text: text,
                type: 'success',
                icon: 'mdi-check',
            })
        },
        toastWarning({commit}, text) {
            commit('addAlert', {
                text: text,
                type: 'warning',
                icon: 'mdi-exclamation',
            })
        },
        toastError({commit}, text) {
            commit('addAlert', {
                text: text,
                type: 'error',
                icon: 'mdi-close',
            })
        }
    },
}