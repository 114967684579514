<template>
    <v-menu
        min-width="290"
        offset-y
        light
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge overlap content="2">
                <v-avatar
                    :to="{path:'/din-side'}"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    size="42"
                >
                    {{initials}}
                </v-avatar>
            </v-badge>
        </template>
        <v-list
            class="grey darken-4"
            dense
            dark
        >
            <v-list-item>
                <v-list-item-avatar
                    color="red">
                    {{initials}}
                </v-list-item-avatar>
                <v-list-item-content class="caption">
                    <v-list-item-title>{{$store.state.auth.user.name}}</v-list-item-title>
                    {{$store.state.auth.user.mail}}
                </v-list-item-content>
            </v-list-item>

            <v-divider class="mt-2 mb-2"/>

            <v-list-item
                link
                :to="{path:'/din-side'}"
                class=""
            >
                <v-list-item-avatar size="20" class="mt-0 mb-0">
                    <v-icon size="20">mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="body-2">
                    Din side
                </v-list-item-content>
                <v-list-item-icon>
                    <v-badge content="2" inline></v-badge>
                </v-list-item-icon>
            </v-list-item>


            <v-list-item
                link
                :to="{path:'/indstillinger'}" dense
                class=""
            >
                <v-list-item-avatar size="20" class="mt-0 mb-0">
                    <v-icon size="20">mdi-cogs</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="body-2">
                    Indstillinger
                </v-list-item-content>
            </v-list-item>

            <v-divider class="mt-2 mb-2"/>

            <v-list-item
                link
                @click="$store.dispatch('auth/logout')"
                class=""
            >
                <v-list-item-avatar size="20" class="mt-0 mb-0">
                    <v-icon size="20">mdi-logout</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="body-2">
                    Log ud
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: "profileBadge",
        data() {
            return {}
        },
        methods: {},
        computed: {

            initials() {
                if (!this.$store.state.auth.status.authorized) {
                    return ''
                }
                let n = this.$store.state.auth.user.name
                return n.slice(0, 1) + n.split(' ')[n.split(' ').length - 1].slice(0, 1)
            }
        }
    }
</script>

<style scoped>

</style>