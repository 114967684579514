import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from "../store/authModule";
import toasts from './toastModule'
import cartModule from "./cartModule";
/*import storageConsent from "./storageConsent";*/

export default new Vuex.Store({
    state: {
        nav: {
            show: true,
            mini: false,
        },
        page: {
            meta: {
                title: ''
            },
            theme:{
                dark:true
            }
        },
        dataTables: {
            breakPoint: 0
        },
    },
    mutations: {},
    actions: {},
    modules: {
        auth,
        toasts,
        cartModule,
        /*storageConsent,*/
    },
    watch: {
        $route(to, from) {
            this.state.page = this.$router.currentRoute
        }
    }
})
