<template>
    <v-tabs-items v-model="tabs" style="background:#333;">
        <v-tab-item class="glass--bg">
            <v-card
                elevation="0"
                height="330px"
                id="loginDialog"
                color="transparent"
            >
                <v-card-title class="font-weight-black">Login</v-card-title>
                <v-card-text>
                    {{$router.currentRoute.query.mode}}
                    <v-form lazy-validation>
                        <v-text-field
                            :rules="[emailValid]"
                            @keydown.enter="login()"
                            autocomplete="username"
                            dense
                            label="Bruger (e-mail)"
                            name="email"
                            outlined
                            prepend-inner-icon="mdi-email"
                            v-model="email"
                        ></v-text-field>
                        <v-text-field
                            :prepend-inner-icon="hidePass ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="hidePass ? 'password':'text'"
                            @click:append="hidePass = !hidePass"
                            @keydown.enter="login()"
                            autocomplete="current-password"
                            dense
                            hide-details
                            label="Adgangskode"
                            name="password"
                            outlined
                            v-model="password"
                        ></v-text-field>
                        <span class="red--text" v-if="!!error">Fejl: {{error}}</span>
                    </v-form>
                </v-card-text>
                <v-sheet class="pl-4 pr-4 transparent">
                    <v-btn :loading="loading" @click="login()" class="col-12 mb-4" color="primary" dark
                           elevation="0">
                        <v-icon class="mr-2">mdi-email-outline</v-icon>
                        Login med E-mail
                    </v-btn>
                    <v-btn @click="tabs=1" class="col-12" color="grey" small text>Jeg er blø' i roen og
                        har glemt ko'en
                    </v-btn>
                </v-sheet>
            </v-card>
        </v-tab-item>

        <v-tab-item>
            <v-card
                elevation="0"
                height="330px"
                min-width="360px"
                v-if="!passwordResetSent"
                width="480"
                class="transparent"

            >
                <v-card-title>Så du er blø' i roen</v-card-title>
                <v-card-text class="text--primary">
                    Send email med link til nulstilling af adgangskode
                    <v-form>
                        <v-text-field
                            :error-messages="this.passwordResetError"
                            :hide-details="this.passwordResetError.length == 0"
                            :hint="this.passwordResetError"
                            :value="emailReset"
                            label="E-mail"
                            name="email"
                            v-model="emailReset"
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn @click="tabs = 0" color="red" dark
                           text>tilbage
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        :loading="resetLinkLoad"
                        @click="sendResetLink()"
                        color="primary"
                        dark
                    >Send link
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card elevation="0"
                    height="330px"
                    min-width="360px"
                    v-else
                    width="480">
                <v-card-title>Så du er blø' i roen</v-card-title>
                <v-card-text class="text--primary">
                    <v-icon color="green">mdi-check</v-icon>
                    Men nu har vi sendt link til nulstilling af ko'en
                </v-card-text>
            </v-card>
        </v-tab-item>
    </v-tabs-items>
</template>

<script>

    import store from '../store'

    export default {
        name: "Login",
        data: () => ({
            email: '',
            password: '',
            hidePass: true,
            user: '',
            firebase: '',
            loading: false,
            rules: {
                email: value => {
                    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
            error: false,
            tabs: 0,
            emailReset: '',
            resetLinkLoad: false,
            passwordResetSent: false,
            passwordResetError: ''
        }),
        methods: {
            login() {
                this.loading = true
                let email = this.email.trim()
                let password = this.password
                this.$store.dispatch('auth/login', {email, password})
                    .then(response => {
                        if (response.data[0].authorized) {
                            this.$emit('loggedIn', true)
                            this.$router.goTo({name:'Oversigt'})
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        switch (err.code) {
                            case 'auth/user-not-found':
                                this.error = 'Bruger ikke fundet'
                                break;
                            case 'auth/invalid-email':
                                this.error = 'Bruger ikke fundet'
                                break;
                            case 'auth/wrong-password':
                                this.error = 'Bruger eller adgangskode ikke korrekt'
                                break;
                            case 'USER_UNAUTHORIZED':
                                this.error = 'Bruger har ikke adgang til systemet'
                                break;
                            default:
                                this.error = false
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            sendResetLink() {
                this.$store.dispatch('auth/sendPasswordReset', this.emailReset)
                    .then(response => {
                        this.passwordResetSent = true
                    })
                    .catch(error => {
                        switch (error) {
                            case 'Firebase: Error (auth/too-many-requests).':
                                this.passwordResetError = 'For mange nulstillinger sendt'
                                break;
                            default:
                                this.passwordResetError = 'Der skete en fejl'
                                break;
                        }
                    })
                this.resetLoad = true
            }
        },
        computed: {
            emailValid: function () {
                if (this.email.trim() === '') {
                    return true
                }
                const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(this.email.trim()) || (`Indtast e-mail`)
            }
        }
    }
</script>

<style scoped>
    input:autofill,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        background-color: rgb(232, 240, 254) !important;
        background-image: none !important;
        color: -internal-light-dark(black, white) !important;
    }
    input:-internal-autofill-selected{
        background-color:#333 !important;
        background:transparent !important;
        appearance:unset  !important;
    }
</style>