<template>
    <v-app :class="$store.state.auth.status.authorized ? 'gradient--background' : ''" class="gradient--background">
        <appBar/>
        <navigation-drawer v-if="$store.state.auth.status.authorized" :routes="routes"/>
        <v-main>
            <router-view/>
            <toasts/>
        </v-main>
        <!--div style="position:fixed;top:5px;left:5px;background:rgba(0,0,0,0.15)">{{breakpoint}}</div-->
    </v-app>
</template>

<script>
    import NavigationDrawer from "./components/navigation/navigationDrawer";
    import toasts from "./components/toasts/toasts";
    import ResetPassword from "./views/resetPassword";
    import api from "./services/api";
    import AppBar from "./components/navigation/appBar";


    export default {
        name: 'App',
        components: {AppBar, ResetPassword, toasts,  NavigationDrawer},
        data() {
            return {
                routes: []
            }
        },
        methods: {
            getDynamicRoutes: function (url) {
                api.get('pages')
                    .then(this.processData)
                    .then(() => {
                        console.log(this.$router.options.routes)
                        this.routes = this.$router.getRoutes()
                    })
                    .catch(err => console.log(err))
            },
            processData: function ({data}) {
                data.forEach((obj, idx) => {
                    this.createAndAppendRoute(obj)
                })
            },
            createAndAppendRoute: function (e) {
                if (this.routeExists(e.name)) {
                    return
                }
                let newRoute = {
                    name: e.name,
                    path: e.path,
                    meta: {
                        icon: e.icon,
                        naviHide: eval(e.naviHide),
                        public: true,
                        parent: e.parent
                    },
                    component: () => import('@/views/' + e.view),
                }
                this.$router.addRoute(newRoute)
                this.$router.options.routes.push(newRoute)
            },
            routeExists(name) {
                const link = this.$router.resolve({name: name})
                return link && link.href !== '/'
            }
        },

        created() {
        },
        mounted() {
            console.log(this.$router)
        },
        watch: {
            '$store.state.auth.status.authorized': {
                handler() {
                    this.$store.state.auth.status.authorized && this.getDynamicRoutes()
                },
                deep: true,
                immediate: true,
            },
        },
        computed: {
            currentCartCounter: function () {
                return this.$store.state.cartModule.orders.length
            },
            breakpoint: function () {
                return this.$vuetify.breakpoint.name
            }
        }
    };
</script>

<style>
    html, body {

    }

    #app {

    }

    #app.light {
        background: #202020;
    }

    .gradient--background {
        background: linear-gradient(140deg, rgb(150, 7, 104) 0%, rgb(217, 68, 98) 100%) !important;
        background: rgb(1, 93, 118) !important;
        background: linear-gradient(139deg, rgba(1, 93, 118, 1) 0%, rgba(252, 70, 107, 1) 100%) !important;
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15) !important;
    }

    .gradient--background--reverse {
        background: rgb(1, 93, 118) !important;
        background: linear-gradient(139deg, rgba(252, 70, 107, 1) 0%, rgba(1, 93, 118, 1) 100%) !important;
    }

    .glass--bg-overlay-old {
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15),
        inset 0 0 15px rgba(255, 255, 255, 0.10) !important;
        backdrop-filter: blur(5px) !important;
        background: linear-gradient(139deg, rgba(1, 93, 118, 0.8) 0%, rgba(252, 70, 107, 0.8) 100%) !important;

    }

    .glass--bg {
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15),
        inset 0 0 15px rgba(255, 255, 255, 0.10) !important;
        background: linear-gradient(
            140deg,
            rgba(255, 255, 255, 0.03) 0%,
            rgba(255, 255, 255, 0.03) 60%,
            rgba(255, 255, 255, 0.08) 61%,
            rgba(255, 255, 255, 0.08) 69%,
            rgba(255, 255, 255, 0.03) 70%,
            rgba(255, 255, 255, 0.03) 100%
        ) !important;
    }

    .glass--bg-overlay {
        box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15),
        inset 0 0 15px rgba(255, 255, 255, 0.10) !important;
        backdrop-filter: blur(5px) !important;
        background: linear-gradient(
            140deg,
            rgba(150, 7, 104, 0.8) 0%,
            rgba(150, 7, 104, 0.8) 60%,
            rgba(150, 7, 104, 0.5) 61%,
            rgba(150, 7, 104, 0.4) 69%,
            rgba(150, 7, 104, 0.8) 70%,
            rgba(150, 7, 104, 0.8) 100%
        ) !important

    }

    .theme--dark.v-card > .v-card__text, .theme--dark.v-card > .v-card__subtitle {
        color: rgba(255, 255, 255, 0.9)
    }

    /*

        .theme--light.v-application {
            background: #f4f6fa !important;
        }
     */
    .theme--light.v-sheet.light--border {
        border: 1px solid #ececec;
    }

    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
        background: #ffffff30 !important;
    }

    .theme--dark.v-tabs-items,
    .theme--dark.v-tabs > .v-tabs-bar {
        background: none;
    }
</style>
