import axios from 'axios'
import store from '../store'

let api = axios.create({
    baseURL: 'https://devapi.kompetencekanalen.dk/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Referrer-Policy": "no-referrer"
    }
})

api.interceptors.request.use(
  config => {
      const user = JSON.parse(localStorage.getItem("user"))
      if (user) {
          config.headers.common["Authorization"] = 'Bearer ' + user.token
      }
      return config
  },
  error => {
      console.log('requestError:', error)
  }
)
api.interceptors.response.use(response => {
      if (response.status === 498) {
          alert('ARGH')
      }
      switch (response.status) {
          case 200:
          case 201:
              return Promise.resolve(response)
          default:
              return Promise.reject(response)
      }
  }, error => {
      switch (error.response.status) {
          case 401:
              store.dispatch('auth/logout')
              if (error.response.code) {
                  store.dispatch('toasts/toastError', error.response.message)
              }
              if (error.response.data.message === 'USER_INACTIVE') {
                  store.dispatch('toasts/toastError', 'Din bruger er ikke længere aktiv')
              }
              return Promise.reject(error.response)
          case 404:
              store.dispatch('auth/logout')
              return Promise.reject(error.response)
          case 498:
              store.dispatch('toasts/toastWarning', 'Din login-session er udløbet.')
              store.dispatch('auth/logout')
              return Promise.reject(error.response)
          default:
              return Promise.reject(error.response)
      }
  }
)


export default api