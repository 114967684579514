<template>
    <v-navigation-drawer
        app
        :mini-variant="$store.state.nav.mini"
        dark
        v-model="$store.state.nav.show"
    >
        <template v-slot:prepend>
            <v-list-item>
                <v-list-item-content>
                    <router-link
                        link
                        exact
                        :to="{path:'/'}">
                        <v-img
                            max-height="54px"
                            max-width="200px"
                            src="/img/logo.svg"
                            contain
                        ></v-img>
                    </router-link>
                </v-list-item-content>
            </v-list-item>
            <!--v-list-item>
                <v-list-item-avatar color="red">
                    {{initials}}
                </v-list-item-avatar>
                <v-list-item-content class="caption">
                    <v-list-item-title>
                        <router-link :to="{path:'din-side'}">{{$store.state.auth.user.name}}</router-link></v-list-item-title>
                    {{$store.state.auth.user.mail}}
                </v-list-item-content>
            </v-list-item-->
            <v-divider/>
        </template>


        <v-list dense shaped>
            <v-subheader class="caption text-uppercase font-weight-black pl-4  mb-2">Menu</v-subheader>
            <v-list-item
                v-for="route in routes"
                v-if="route.meta.naviHide == 0 && route.meta.parent === 0"
                text
                exact
                class="mr-4"
                :key="route.name"
                :to="{name:route.name}"
            >
                <v-list-item-avatar size="20" tile>
                    <v-icon>{{route.meta.icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{route.name}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="route.name === 'Kurv' && $store.state.cartModule.orders.length >= 1">
                    <v-btn icon class="blue" x-small>{{$store.state.cartModule.orders.length}}</v-btn>
                </v-list-item-action>
            </v-list-item>

        </v-list>

        <v-list dense shaped>
            <v-subheader class="caption text-uppercase font-weight-black pl-4  mb-2">Dine sider</v-subheader>
            <v-list-item
                v-for="route in routes"
                :key="route.name"
                :to="{name:route.name}"
                v-if="route.meta.naviHide === 0 && route.meta.parent === 1"
                exact
                link
            >
                <v-list-item-avatar size="20" tile>
                    <v-icon>{{route.meta.icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{route.name}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list class="pa-0" dense shaped>
                <v-list-item @click="$store.dispatch('auth/logout')" class="border-top-light" exact link>
                    <v-list-item-avatar size="20" tile>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import api from "../../services/api";

    export default {
        name: "navigationDrawer",
        props: ['routes'],
        data() {
            return {
                currentPath: this.$router.currentRoute.path,
            }
        },
        methods: {
            navState() {
                if (this.breakpoint === 'lg' || this.breakpoint === 'xl') {
                    this.$store.state.nav.show = true
                }
            }
        },
        watch: {
            '$vuetify.breakpoint.name': {
                handler(name) {
                    if (['md', 'lg', 'xl'].includes(name)) {
                        this.$store.state.nav.show = false
                    }
                },
                deep: true,
                immediate: true,
            },
            'breakpoint': {
                handler() {
                    this.navState()
                }
            }
        },
        mounted() {
            this.navState()
        },
        created() {

        },
        computed: {
            currentCartCounter: function () {
                return this.$store.state.cartModule.orders.length
            },
            initials() {
                let n = this.$store.state.auth.user.name
                return n.slice(0, 1) + n.split(' ')[n.split(' ').length - 1].slice(0, 1)
            },
            breakpoint: function () {
                return this.$vuetify.breakpoint.name
            }
        }

    }
</script>

<style scoped>
</style>
