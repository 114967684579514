<template>
    <v-dialog
        v-model="dialog"
        max-width="400px"
    >
        <login @loggedIn="closeDialog"/>
        <template v-slot:activator="{on,attrs}">
            <v-btn @click="dialog = !dialog" outlined light>
                Log ind
            </v-btn>
        </template>
    </v-dialog>
</template>

<script>
    import Login from "../../views/Login";

    export default {
        name: "loginDialog",
        components: {Login},
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            closeDialog() {
                this.dialog = false
            }
        },
        computed: {}
    }
</script>

<style>
</style>