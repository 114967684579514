import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import vuex from '@/store'

Vue.use(Vuetify);
console.log(vuex.state)
export default new Vuetify({
    theme:{
        dark:vuex.state.page.theme.dark
    },
});
