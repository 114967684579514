import Vue from 'vue'
import VueRouter from 'vue-router'
import resetPassword from '../views/resetPassword.vue'
import api from "../services/api";

Vue.use(VueRouter)

let routes = [
    {
        name: "Forside",
        path: "/",
        meta: {
            icon: "mdi-format-list-bulleted",
            naviHide: 1,
            parent:0
        },
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/nulstil-adgangskode',
        name: 'nulstil-adgangskode',
        component: resetPassword,
        meta: {
            title: 'nulstil-adgangskode'
        },
        icon: 'mdi-account',
        naviHide: 1,
    },
    {
        path: '/cookies',
        name: 'Cookies',
        meta: {
            title: 'Cookiepolitik'
        },
        icon: 'mdi-cookie',
        naviHide: 1,
        component:()=>import('@/views/Cookies.vue')
    },
    {
        path: '/handelsbetingelser',
        name: 'Handelsbetingelser',
        meta: {
            title: 'Handelsbetingelser'
        },
        icon: 'mdi-cookie',
        naviHide: 1,
        component:()=>import('@/views/Terms.vue')
    },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router
