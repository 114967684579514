var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"col-sm-12",staticStyle:{"top":"38px"},attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"pa-0",attrs:{"icon":"","rounded":""}},[(_vm.notifications.unread.length > 0)?_c('v-badge',{attrs:{"content":_vm.notifications.unread.length,"overlap":""}},[_c('v-icon',[_vm._v("mdi-bell")])],1):_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar-title',{staticClass:"pl-0 darken-4 v-subheader font-weight-bold "},[_vm._v("Notifikationer ("+_vm._s(_vm.notifications.unread.length)+") ")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attr,false),on),[_vm._v("mdi-dots-horizontal ")])],1)]}}])},[_c('v-card',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.markAllRead()}}},[_c('v-list-item-content',{staticClass:"caption"},[_vm._v(" Markér alle læst ")])],1)],1)],1)],1),_c('v-divider'),(_vm.notifications.unread.length >= 1)?_c('div',[_c('v-card-subtitle',{staticClass:"font-weight-bold pb-0 pt-2 caption text-uppercase"},[_vm._v("Nye")]),_c('v-virtual-scroll',{attrs:{"items":_vm.notifications.unread,"item-height":56,"max-height":"calc(90vh - 194px)","min-height":"56","width":"360px","max-width":"100vw","bench":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"notificationListItem notificationListItemRead flex ",attrs:{"elevation":"0","link":""}},[_c('v-list-item-avatar',{attrs:{"color":"indigo","size":"40"}},[_c('span',{staticClass:"white--text"},[_vm._v("36")])]),_c('v-list-item-content',{staticClass:"pt-0 pb-0"},[_c('v-list-item-title',{staticClass:"subtitle-2",attrs:{"color":" grey darken-1"}},[_vm._v(" "+_vm._s(item.title)+" "),_c('br'),_c('span',{staticClass:"text-truncate subtitle-2 font-weight-regular"},[_vm._v("asfasf")]),_c('v-list-item-subtitle',{staticClass:"caption blue--text text--darken-1 text-truncate float-right"},[_vm._v(" 2 timer siden ")])],1)],1)],1)]}}],null,false,3420969012)})],1):_vm._e(),_c('v-divider'),(_vm.notifications.read.length >= 1)?_c('div',[_c('v-card-subtitle',{staticClass:"font-weight-bold pb-0 pt-2 caption text-uppercase"},[_vm._v("Sete")]),_c('v-virtual-scroll',{attrs:{"items":_vm.notifications.read,"item-height":56,"max-height":"calc(90vh - 194px)","min-height":"56","width":"360px","max-width":"100vw","bench":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"notificationListItem notificationListItemRead flex ",attrs:{"elevation":"0","link":""}},[_c('v-list-item-avatar',{attrs:{"color":"indigo","size":"40"}},[_c('span',{staticClass:"white--text"},[_vm._v("36")])]),_c('v-list-item-content',{staticClass:"pt-0 pb-0"},[_c('v-list-item-title',{staticClass:"subtitle-2",attrs:{"color":" grey darken-1"}},[_vm._v(" "+_vm._s(item.title)+" "),_c('br'),_c('span',{staticClass:"text-truncate subtitle-2 font-weight-regular"},[_vm._v(_vm._s(item.reference))]),_c('v-list-item-subtitle',{staticClass:"caption blue--text text--darken-1 text-truncate float-right"},[_vm._v(" 2 timer ")])],1)],1)],1)]}}],null,false,789464860)})],1):_vm._e(),_c('v-divider'),_c('v-footer',{staticClass:"text-center"},[_c('v-spacer'),_c('router-link',{attrs:{"to":{name:'Oversigt'}}},[_vm._v("Se flere")]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }