<template>
    <v-menu
        offset-y
        style="top: 38px;"
        class="col-sm-12"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <v-btn icon rounded class="pa-0">
                    <v-badge
                        v-if="$store.state.cartModule.orders.length >= 1"
                        :content="$store.state.cartModule.orders.length"
                        overlap
                        bordered
                        light
                    >
                        <v-icon>mdi-cart</v-icon>
                    </v-badge>
                    <v-icon v-else>mdi-cart</v-icon>
                </v-btn>
            </div>
        </template>
        <v-card>
            <v-toolbar dense elevation="0">
                <v-toolbar-title class="pl-0 darken-4 v-subheader font-weight-bold ">Bestillinger ({{$store.state.cartModule.orders.length}})
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu>
                    <template v-slot:activator="{on,attr}">
                        <v-btn icon>
                            <v-icon
                                v-bind="attr"
                                v-on="on"
                            >mdi-dots-horizontal
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list-item @click="$store.dispatch('cartModule/emptyCart')" link>
                            <v-list-item-content class="caption">
                                Tøm kurven
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-menu>
            </v-toolbar>
            <v-divider/>
            <v-virtual-scroll
                :items="$store.state.cartModule.orders"
                :item-height="56"
                max-height="calc(90vh - 194px)"
                min-height="56"
                width="360px"
                max-width="100vw"
                bench="10"
            >
                <template v-slot="{item}">
                    <v-list-item
                        elevation="0"
                        link
                        v-if="$store.state.cartModule.orders.length >= 1"
                    >
                        <v-list-item-content class="pt-0 pb-0">
                            <v-list-item-title class="subtitle-2" color=" grey darken-1">
                                {{item.amount}} * {{item.domain}}
                                <v-list-item-subtitle
                                    class="caption blue--text text--darken-1 text-truncate ">
                                    {{(item.price * item.amount).toLocaleString('da-dk')}}
                                </v-list-item-subtitle>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="ma-0">
                            <v-btn icon small>
                                <v-icon size="12" color="grey lighten-1" @click="$store.dispatch('cartModule/removeFromCart',item.id)">mdi-close</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-virtual-scroll>
            <v-footer class="pa-0">
                <v-btn class="ma-0" width="100%" tile color="green" dark link :to="{name:'Bestillinger'}">Se bestillinger</v-btn>
            </v-footer>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: "cart",
        data() {
            return {
                items: [
                    {name: 'whatever', price: 2000}
                ]
            }
        }
    }
</script>

<style scoped>

</style>