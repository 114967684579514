import Vue from 'vue'
import App from './App.vue'
import mixins from './mixins/mixins'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {initializeApp} from 'firebase/app';
import AOS from 'aos'
import 'aos/dist/aos.css'

require('@/extensions/ext.js')

initializeApp({
    apiKey: "AIzaSyChNyQr8aDR3a0hWoCz-zj_nNZAFRWoY60",
    authDomain: "kompetencekanalen.firebaseapp.com",
    projectId: "kompetencekanalen",
    storageBucket: "kompetencekanalen.appspot.com",
    messagingSenderId: "233902917786",
    appId: "1:233902917786:web:942aca8982493472c07723",
    measurementId: "G-2G7YK9HQG4"
})


export const bus = new Vue()
Vue.config.productionTip = false
Vue.mixin(mixins)
Vue.use(AOS.init())
Vue.use(vuetify, {
    options: {
        customProperties: true,
    },
    customVariables: '~/sass/variables.sass',
    treeShake: true,
    theme: {
        primary: '#ad33a3',
        secondary: '#1b3e70',
        accent: '#ff643d',
        error: '#ff643d'
    }
})
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')

