var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"col-sm-12",staticStyle:{"top":"38px"},attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"pa-0",attrs:{"icon":"","rounded":""}},[(_vm.$store.state.cartModule.orders.length >= 1)?_c('v-badge',{attrs:{"content":_vm.$store.state.cartModule.orders.length,"overlap":"","bordered":"","light":""}},[_c('v-icon',[_vm._v("mdi-cart")])],1):_c('v-icon',[_vm._v("mdi-cart")])],1)],1)]}}])},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar-title',{staticClass:"pl-0 darken-4 v-subheader font-weight-bold "},[_vm._v("Bestillinger ("+_vm._s(_vm.$store.state.cartModule.orders.length)+") ")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attr,false),on),[_vm._v("mdi-dots-horizontal ")])],1)]}}])},[_c('v-card',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$store.dispatch('cartModule/emptyCart')}}},[_c('v-list-item-content',{staticClass:"caption"},[_vm._v(" Tøm kurven ")])],1)],1)],1)],1),_c('v-divider'),_c('v-virtual-scroll',{attrs:{"items":_vm.$store.state.cartModule.orders,"item-height":56,"max-height":"calc(90vh - 194px)","min-height":"56","width":"360px","max-width":"100vw","bench":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.cartModule.orders.length >= 1)?_c('v-list-item',{attrs:{"elevation":"0","link":""}},[_c('v-list-item-content',{staticClass:"pt-0 pb-0"},[_c('v-list-item-title',{staticClass:"subtitle-2",attrs:{"color":" grey darken-1"}},[_vm._v(" "+_vm._s(item.amount)+" * "+_vm._s(item.domain)+" "),_c('v-list-item-subtitle',{staticClass:"caption blue--text text--darken-1 text-truncate "},[_vm._v(" "+_vm._s((item.price * item.amount).toLocaleString('da-dk'))+" ")])],1)],1),_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"size":"12","color":"grey lighten-1"},on:{"click":function($event){return _vm.$store.dispatch('cartModule/removeFromCart',item.id)}}},[_vm._v("mdi-close")])],1)],1)],1):_vm._e()]}}])}),_c('v-footer',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"ma-0",attrs:{"width":"100%","tile":"","color":"green","dark":"","link":"","to":{name:'Bestillinger'}}},[_vm._v("Se bestillinger")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }