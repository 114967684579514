<template>
    <div class="mx-auto col-lg-5 col-sm-12 col-xs-12 col-md-12">
        <v-card class="" v-if="!passwordChanged">
            <v-card-title>Indtast ny adgangskode</v-card-title>
            <v-card-text>
                <v-text-field
                    :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="hidePass ? 'password' : 'text'"
                    @click:append="hidePass = !hidePass"
                    label="Ny adgangskode"
                    v-model="user.password"
                    autocomplete="new-password"
                    :error="this.errorMessage.length >= 1"
                    :error-messages="this.errorMessage"
                    @keydown.enter="confirmResetCode()"
                />
            </v-card-text>
            <v-card-actions class="pa-4 pt-0">
                <v-spacer/>
                <v-btn text color="primary" small link href="/">Tilbage til forsiden</v-btn>
                <v-btn @click="confirmResetCode()" color="primary" small :loading="passwordChangeLoading">Gem adgangskode</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else>
            <v-card-title>Indtast ny adgangskode</v-card-title>
            <v-card-text class="text--primary">
                <v-icon color="green">mdi-check</v-icon>
                Adgangskode for {{this.user.email}} ændret
            </v-card-text>
            <v-card-actions class="pa-4 pt-0">
                <v-spacer/>
                <v-btn text color="primary" small link href="/">Tilbage til forsiden</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import {verifyPasswordResetCode, confirmPasswordReset, getAuth} from "firebase/auth";

    export default {
        name: "resetPassword",
        data() {
            return {
                user: {
                    password: ''
                },
                hidePass: true,
                errorMessage: '',
                passwordChangeLoading: false,
                passwordChanged: false,
            }
        },
        methods: {
            confirmResetCode() {
                const auth = getAuth()
                const actionCode = this.$router.currentRoute.query.oobCode
                const newPassword = this.user.password
                verifyPasswordResetCode(auth, actionCode).then((email) => {
                    console.log(email)
                    this.user.email = email
                    this.passwordChangeLoading = true
                    // Save the new password.
                    confirmPasswordReset(auth, actionCode, newPassword)
                        .then(response => {
                            console.log(response)
                            // TODO: Display a link back to the app, or sign-in the user directly
                            // if the page belongs to the same domain as the app:
                            this.passwordChanged = true

                            // TODO: If a continue URL is available, display a button which on
                            // click redirects the user back to the app via continueUrl with
                            // additional state determined from that URL's parameters.
                        })
                        .catch(response => {
                            console.log(response.error)
                            switch (response.code) {
                                case 'auth/weak-password':
                                    this.errorMessage = 'Adgangskode for svag; brug min 6 tegn, både tal og bogstaver'
                                    break;
                                default:
                                    this.errorMessage = 'Der skete en fejl, prøv igen'
                                    break;
                            }
                        })

                    this.passwordChangeLoading = false
                })
                    // oobCode validation catch
                    .catch(error => {
                        console.log(error.code)
                        console.log(error.message)
                        switch (error.code) {
                            case 'auth/weak-password':
                                this.errorMessage = 'Adgangskode for svag; brug min. 8 bogstaver og tal'
                                break;
                            case 'auth/expired-action-code':
                                this.errorMessage = 'Nulstillingskode uløbet, prøv igen'
                                break;
                            case 'auth/invalid-action-code':
                                this.errorMessage = 'Nulstillingskoden er udløbet eller ugyldig. '
                                break;
                            default:
                                this.errorMessage = 'Der skete en oobCode fejl'
                                break;
                        }
                        this.passwordChangeLoading = false
                    })
            },
        },
    }
</script>

<style scoped>

</style>