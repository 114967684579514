<template>
    <v-app-bar
        height="64"
        max-height="64"
        :class="$store.state.auth.status.authorized ? 'gradient--background' :'white'"
        light
        elevation="1"
    >
        <v-toolbar
            elevation="0"
            class="transparent hidden-sm-and-down"
            dark
        >
            <router-link
                link
                exact
                :to="{path:'/'}"
                v-if="!$store.state.auth.status.authorized">
                <v-img
                    max-height="54px"
                    max-width="200px"
                    src="/img/logo.svg"
                    contain
                ></v-img>
            </router-link>
            <v-app-bar-nav-icon @click="$store.state.nav.show = !$store.state.nav.show"/>
            <v-spacer/>
            <profile-badge v-if="$store.state.auth.status.authorized"/>
            <div v-else>
                <v-btn text :to="{path:'/cookies'}" class="mr-4" light>cookies</v-btn>
                <v-btn text :to="{path:'/handelsbetingelser'}" class="mr-4" light>Handelsbetingelser</v-btn>
                <login-dialog/>
            </div>
        </v-toolbar>
        <v-toolbar
            elevation="0"
            class="transparent hidden-md-and-up"
            dark
        >
            <router-link
                link
                exact
                :to="{path:'/'}">
                <v-img
                    max-height="54px"
                    max-width="200px"
                    src="/img/logo.svg"
                    contain
                ></v-img>
            </router-link>
            <v-spacer/>
            <v-app-bar-nav-icon
                @click="$store.state.nav.show = !$store.state.nav.show"
                class="hidden-md-and-up"
            ></v-app-bar-nav-icon>
        </v-toolbar>
    </v-app-bar>
</template>

<script>
    import cartBadge from "../cart/cartBadge";
    import api from "../../services/api";
    import LoginDialog from "../user/loginDialog";
    import NotificationBadge from "../user/notificationBadge";
    import ProfileBadge from "../user/profileBadge";

    export default {
        name: "appBar",
        props: ['routes'],
        data() {
            return {
                pageName: '',

            }
        },
        components: {ProfileBadge, NotificationBadge, LoginDialog, cartBadge},
        methods: {},
        computed: {
            currentCartCounter() {
                return this.$store.state.cartModule.orders.length
            },

        },
        created() {
        },
        mounted() {
            this.pageName = this.$router.currentRoute.name
        },
        watch: {
            '$route': {
                handler() {
                    this.pageName = this.$router.currentRoute.name
                }
            },
        }
    }
</script>

<style scoped>

</style>
