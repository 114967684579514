/* eslint-disable no-console */
import {register} from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is available: Refreshing.')
            if ('serviceWorker' in navigator) {
                caches.keys()
                    .then(function (cacheNames) {
                        cacheNames.forEach(function (cacheName) {
                            caches.delete(cacheName)
                        })
                    })
                    .finally(window.location.reload(true))
            }

        },
        updated(registration) {
            console.log('New content is available; please refresh.')
            const worker = registration.waiting;
            worker.postMessage({action: "SKIP_WAITING"});
            // refresh the page or trigger a refresh programatically!
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
